import React from 'react'
import { graphql } from 'gatsby'

import { Bio } from '../components/layout/Bio'
import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { ItemsList } from '../components/page/ItemsList'
import { ProjectItem } from '../components/page/ProjectItem'
import { H2 } from '../components/styled/H2'

function AllProjects({
  data: {
    allProjects: { edges: projects },
  },
}) {
  return (
    <Layout>
      <SEO
        title="All Projects"
        image={`/og-images/projects/projects-page.png`}
      />
      <Bio />
      <ItemsList
        header={<H2>All My Projects</H2>}
        items={projects}
        component={ProjectItem}
      />
    </Layout>
  )
}

export default AllProjects

export const pageQuery = graphql`
  query {
    allProjects: allSanityProject(
      sort: { fields: [creationDate], order: DESC }
    ) {
      edges {
        node {
          ...ProjectItem
        }
      }
    }
  }
`
